import { EmailRegexString } from "@superblocksteam/shared";
import { noop } from "lodash";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Popper from "components/ui/Popper";

import { useDebounce, usePointerDownOutside } from "hooks/ui";
import InputComponent from "legacy/widgets/InputWidget/InputComponent";
import derivedInputFunctions from "legacy/widgets/InputWidget/derived";
import { CompactMode, EditInputType, EditProps } from "../Constants";
import { Position, InputCellWrapper } from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const EditInputCell = (props: {
  editProps: EditProps;
  value: any;
  targetCellRef: RefObject<HTMLElement>;
  inputPosition: Position;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const { value, editProps, targetCellRef, inputPosition } = props;
  const {
    handleEditStop,
    editInputType,
    editCustomValidationRule,
    editCustomErrorMessage,
    editMaxLength,
    editMinLength,
    editIsRequired,
    handleEditChange,
  } = editProps;
  const [inputValue, setInputValue] = useState(
    typeof value === "object" && value ? JSON.stringify(value, null, 2) : value,
  );
  const inputMemo = useRef<string>(inputValue);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const validateValue = useCallback(
    (toValidate?: string) => {
      const errors: Record<string, string | undefined> =
        derivedInputFunctions.getValidationErrors({
          inputType: editInputType,
          text: String(toValidate ?? ""),
          emailRegex: new RegExp(`^${EmailRegexString}$`),
          customValidationRule: String(editCustomValidationRule),
          customErrorMessage: editCustomErrorMessage,
          maxLength: editMaxLength,
          minLength: editMinLength,
          isRequired: editIsRequired,
        });
      const validationErrors = Object.values(errors).filter(
        Boolean,
      ) as string[];
      setValidationErrors(validationErrors);
      return validationErrors;
    },
    [
      editInputType,
      editCustomValidationRule,
      editCustomErrorMessage,
      editMaxLength,
      editMinLength,
      editIsRequired,
    ],
  );
  useEffect(() => {
    validateValue(inputMemo.current);
  }, [validateValue]);
  const debouncedValidation = useDebounce(validateValue, 50);

  const handleValueChange = useCallback(
    (val: any) => {
      setInputValue(val);
      inputMemo.current = val;
      handleEditChange(val);
      debouncedValidation && debouncedValidation(val);
    },
    [debouncedValidation, handleEditChange],
  );

  const handleKeyDown = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const errors = validateValue(inputMemo.current);
        handleEditStop({
          shouldSave: true,
          value: inputMemo.current,
          validationErrors: errors,
        });
      }

      if (e.key !== "Tab" && e.key !== "Escape" && e.key !== "Enter") {
        // Limit most keypresses within focused inputs. These events will
        // bubble to the TableWidget key handler, though
        e.stopPropagation();
      }
    },
    [handleEditStop, validateValue],
  );

  const saveChange = useCallback(() => {
    handleEditStop({
      shouldSave: true,
      value: inputValue,
      validationErrors,
    });
  }, [handleEditStop, inputValue, validationErrors]);

  usePointerDownOutside({
    wrapperRefs: [inputWrapperRef],
    wrapperSelectors: ["[data-test=table-edit-input]"],
    onClickOutside: saveChange,
  });

  useEffect(() => {
    return () => {
      // stop editing w/o saving when component unmounts
      handleEditStop({ shouldSave: false });
    };
  }, [handleEditStop]);
  if (
    !targetCellRef.current ||
    typeof inputPosition?.top === "undefined" ||
    // Validate that the intended element exists
    !(targetCellRef.current.childNodes?.[0] as HTMLElement)?.getAttribute?.(
      "data-superblocks",
    )
  ) {
    // todo render normal cell or something
    return null;
  }

  return (
    <Popper
      targetNode={targetCellRef.current.childNodes[0] as HTMLElement}
      isOpen={true}
      placement="auto"
      useDefaultModifiers={false}
      zIndex={1}
    >
      <InputCellWrapper
        data-test={`table-edit-input`}
        $position={inputPosition}
        $compactMode={props.compactMode}
        cellProperties={props.cellProps.cellProperties}
      >
        <InputComponent
          value={inputValue}
          inputType={editInputType ?? EditInputType.Text}
          label={""}
          onValueChange={handleValueChange}
          placeholder={"Enter text"}
          multiline={!editInputType || editInputType === EditInputType.Text}
          onKeyDown={handleKeyDown}
          isLoading={false}
          isInvalid={validationErrors.length > 0}
          showError={validationErrors.length > 0}
          errorMessages={validationErrors}
          onFocusChange={noop}
          widgetId="unused"
          widgetType="TABLE_WIDGET"
          numLines={1}
          autoFocus={Boolean(inputPosition?.top && inputPosition?.width)}
          growVertically={true}
          disableDebounce={true}
          preventFormattingWhileTyping={true}
        />
      </InputCellWrapper>
    </Popper>
  );
};

export default EditInputCell;
