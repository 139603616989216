import { Button, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { PlainLink } from "components/ui/Link";
import Popper from "components/ui/Popper";
import Icon, { IconSize } from "legacy/components/ads/Icon";
import { CLASS_NAMES } from "legacy/themes/classnames";
import {
  SingleCellProperties,
  CompactMode,
  CompactModeTypes,
  TABLE_SIZES,
} from "../Constants";
import {
  CellWrapper,
  DefaultValueWrapper,
  getCellClass,
  getCellWrapStyles,
  buildCellStyles,
} from "../TableStyledWrappers";

const LinkLike = styled.div`
  cursor: pointer;
`;

const IconStyes = css<{ compactMode: CompactMode }>`
  height: ${({ compactMode }) => {
    return TABLE_SIZES[compactMode]?.LINK_MENU_HEIGHT ?? 32;
  }}px;
  padding: ${({ compactMode }) =>
    compactMode === CompactModeTypes.VERY_SHORT
      ? "7px"
      : compactMode === CompactModeTypes.SHORT
      ? "9px"
      : "10px"};
`;

const StyledButton = styled(Button)<{ compactMode: CompactMode }>`
  border: none;
  ${IconStyes}
`;

const LinkMenu = styled.div<{ compactMode: CompactMode }>`
  display: flex;
  align-items: center;
  a {
    ${IconStyes}
  }

  svg > path {
    fill: none !important;
  }
`;

const OpenLinkButton = ({
  url,
  openInNewTab,
  compactMode,
}: {
  url: string;
  openInNewTab: boolean | undefined;
  compactMode: CompactMode;
}) => {
  return (
    <Tooltip title={"Open"}>
      {openInNewTab === undefined || openInNewTab === true ? (
        <Link
          to={url}
          relative={"path"}
          target="_blank"
          rel="noreferrer nofollow"
          aria-disabled={true}
          data-test="table-cell-link-open"
        >
          <Icon
            name="external-link"
            size={
              TABLE_SIZES[compactMode]?.LINK_MENU_ICON_SIZE ?? IconSize.LARGE
            }
          />
        </Link>
      ) : (
        <PlainLink
          href={url}
          rel="noreferrer nofollow"
          target="_parent"
          data-test="table-cell-link-open"
        >
          <Icon
            name="external-link"
            size={
              TABLE_SIZES[compactMode]?.LINK_MENU_ICON_SIZE ?? IconSize.LARGE
            }
          />
        </PlainLink>
      )}
    </Tooltip>
  );
};

export const EditableLinkCell = (props: {
  url: string;
  label: string;
  openInNewTab: boolean | undefined;
  isHidden: boolean;
  isEdited: boolean;
  value: any;
  cellProperties: SingleCellProperties;
  compactMode: CompactMode;
  isFocused: boolean;
  position: { currentRowIndex: number; columnId: string };
  defaultValue?: string;
  maxLinesPerRow?: number;
  isMultiselecting: boolean;
}) => {
  const {
    isHidden,
    url,
    label,
    openInNewTab,
    isEdited,
    value,
    cellProperties,
    compactMode,
    isFocused,
    defaultValue,
    maxLinesPerRow,
    isMultiselecting,
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMenuOpen && !isFocused) {
      setIsMenuOpen(false);
    } else if (!isMenuOpen && isFocused) {
      setIsMenuOpen(true);
    }
  }, [isFocused, isMenuOpen]);

  const {
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    textWrap,
  } = cellProperties;

  const [className, styles] = useMemo(() => {
    const options = {
      canWrap: textWrap,
      maxLines: maxLinesPerRow,
    };
    return [
      getCellClass(options),
      {
        ...getCellWrapStyles(options),
        ...buildCellStyles({
          cellTextStyle,
          fontStyle,
          textColor,
          horizontalAlignment,
          verticalAlignment,
          textSize,
          compactMode,
        }),
      },
    ];
  }, [
    textWrap,
    compactMode,
    maxLinesPerRow,
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
  ]);

  return (
    <CellWrapper
      ref={cellRef}
      isHidden={isHidden}
      cellProperties={cellProperties}
      compactMode={compactMode}
      className={className}
      style={styles}
    >
      {!value && defaultValue ? (
        <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper>
      ) : (
        <LinkLike
          style={{
            color: props.cellProperties.textColor,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          className={CLASS_NAMES.LINK}
        >
          {isEdited ? value : label}
        </LinkLike>
      )}
      {isMenuOpen && (
        <Popper
          zIndex={3}
          isOpen={isMenuOpen}
          targetNode={cellRef.current || undefined}
          placement={"bottom-start"}
          ref={popperRef}
        >
          <LinkMenu
            data-test="table-cell-link-menu"
            compactMode={compactMode}
            className={CLASS_NAMES.POPOVER_WRAPPER}
            style={
              isMultiselecting
                ? {
                    display: "none",
                  }
                : undefined
            }
          >
            <Tooltip title="Copy">
              <StyledButton
                compactMode={compactMode}
                onClick={() => {
                  copy(url);
                }}
                data-test="table-cell-link-copy"
                type="link"
              >
                <Icon name="copy" size={IconSize.LARGE} />
              </StyledButton>
            </Tooltip>
            <OpenLinkButton
              url={url}
              openInNewTab={openInNewTab}
              compactMode={compactMode}
            />
          </LinkMenu>
        </Popper>
      )}
    </CellWrapper>
  );
};
